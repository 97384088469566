<template>
  <div class="container">
    <div id="loader">
      <div class="text-center" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </div>

    <div id="content" v-if="survey">
      <div class="row mb-2">
        <div class="col-md-12">
          <h4 class="h4">
            {{ survey.title }}
          </h4>
        </div>
      </div>

      <b-form @submit.prevent="doSubmit()" novalidate>
        <div class="row" v-if="+survey.type === 1">
          <!-- Gov Id-->
          <div class="col-md-6 col-sm-12">
            <b-form-group class="form-label" label="Número de cédula">
              <b-input-group id="kgroup" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input min="0" type="number" v-model="form.govId" @keyup.enter.native="doSearchEmployee()"
                  placeholder="Número de cédula" required v-on:keydown.enter.prevent>
                </b-form-input>

                <div class="text-center" v-if="loadingEmployee">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Cargando...</span>
                  </div>
                </div>

                <b-button class="is-primary" type="button" :disabled="!isValidId()" role="button"
                  @click="doSearchEmployee()" variant="primary">
                  Buscar
                </b-button>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Gov Id-->

        <!-- Company -->
        <div class="col-md-6 col-sm-12">
          <b-form-group class="form-label" label="Compañía la que labora">
            <b-form-select
              v-model="form.database"
              :options="companies"
            >
            </b-form-select>
          </b-form-group>
        </div>
        <!-- Company -->
        </div>

        <div class="row" v-if="+survey.type === 2">
          <div class="col-md-6 col-sm-12">
            <b-form-group class="form-label" label="Nombre">
              <b-form-input
                v-model="form.name"
                placeholder="Nombre"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group class="form-label" label="Empresa para la que labora">
              <b-form-input
                v-model="form.company"
                placeholder="Empresa"
              >
              </b-form-input>
            </b-form-group>
          </div>
        </div>

        <hr />

        <div id="survey" class="mb-5" v-if="loadedEmployee || checkClient()">
          <div id="questions" v-for="(question, idx) in survey.questions" :key="question.id">

            <!-- SELECCION UNICA -->
            <div class="row" v-if="question.type == 1">
              <div class="col-md-12 mb-3">
                <label>
                  {{ idx + 1 }}. {{ question.title }}
                </label>
                <div v-for="option in question.options" :key="option.id">
                  <b-form-radio v-model="form.answers[idx].options" :value="option.id">
                    {{ option.content }}
                  </b-form-radio>
                </div>
                <div v-if="question.detailAllowed">
                  <div class="mt-2">
                    <label>Comentario</label>
                    <b-form-textarea v-model="form.answers[idx].detail"> </b-form-textarea>
                  </div>
                </div>
              </div>
            </div>


            <!-- SELECCION MULTIPLE -->
            <div class="row" v-if="question.type == 2">
              <div class="col-md-12 mb-3">
                <label>
                  {{ idx + 1 }}. {{ question.title }}
                </label>
                <div v-for="option in question.options" :key="option.id">
                  <b-form-checkbox v-model="form.answers[idx].options" :value="option.id">
                    {{ option.content }}
                  </b-form-checkbox>
                </div>
                <div v-if="question.detailAllowed">
                  <div class="mt-2">
                    <label>Comentario</label>
                    <b-form-textarea v-model="form.answers[idx].detail"> </b-form-textarea>
                  </div>
                </div>
              </div>
            </div>

            <!-- SELECCION MULTIPLE LIMITADA-->
            <div class="row" v-if="question.type == 3">
              <div class="col-md-12 mb-3">
                <label>
                  {{ idx + 1 }}. {{ question.title }}
                </label>
                <div v-for="(option) in question.options" :key="option.id">
                  <b-form-checkbox @change="changeStatus(idx, option.id)"
                    :disabled="form.answers[idx].options.length >= MAX_CHECKBOXES && !isChecked(idx, option.id)">
                    {{ option.content }}
                  </b-form-checkbox>
                </div>
                <div v-if="question.detailAllowed">
                  <div class="mt-2">
                    <label>Comentario</label>
                    <b-form-textarea v-model="form.answers[idx].detail"> </b-form-textarea>
                  </div>
                </div>
              </div>
            </div>

            <!-- SELECCION DE RANGO -->
            <div class="row">
              <div class="col-md-12" v-if="question.type == 4">
                <label>
                  {{ idx + 1 }}. {{ question.title }}
                </label>
                <b-form-radio-group v-model="form.answers[idx].options" buttons class="col pt-2 pb-3">
                  <template v-for="option in question.options">
                    <b-form-radio :class="
                      form.answers[idx] == question.id
                        ? 'custom-button-selected'
                        : 'custom-button'
                    " :value="option.id" :key="option.content">
                      {{ option.content }}
                    </b-form-radio>
                  </template>
                </b-form-radio-group>
                <div v-if="question.detailAllowed">
                  <div class="mt-2 mb-3">
                    <b-form-textarea v-model="form.answers[idx].detail">
                    </b-form-textarea>
                  </div>
                </div>
              </div>
            </div>

            <!-- CAMPO DE TEXTO -->
            <div class="row" v-if="question.type == 5" style="margin-bottom: 20px">
              <div class="col-md-12">
                <label> {{ idx + 1 }}. {{ question.title }} </label>
                <b-form-textarea v-model="form.answers[idx].detail"> </b-form-textarea>
              </div>
            </div>

            <!-- ENNUMERACION -->
            <div class="row" v-if="question.type == 6">
              <div class="col-md-12 mb-3">
                <label>
                  {{ idx + 1 }}. {{ question.title }}
                </label>
                <div v-for="(option, jdx) in question.options" :key="option.id">
                  <div class="row justify-content-start">
                    <div class="col-md-1 col-sm-2">
                      <b-form-select v-model="form.answers[idx].options[jdx].priority"
                        :options="priority"></b-form-select>
                    </div>
                    <div class="col-4">
                      {{ option.content }}
                    </div>
                  </div>
                </div>
                <div v-if="question.detailAllowed">
                  <div class="mt-2">
                    <label>Comentario</label>
                    <b-form-textarea v-model="form.answers[idx].detail"> </b-form-textarea>
                  </div>
                </div>
              </div>
            </div>

            <!-- DROPDOWN -->
            <div class="row" v-if="question.type == 7">
              <div class="col-md-6 mb-3">
                <label>
                  {{ idx + 1 }}. {{ question.title }}
                </label>
                <b-form-select value-field="id" text-field="content" v-model="form.answers[idx].options"
                  :options="question.options"></b-form-select>
                <div v-if="question.detailAllowed">
                  <div class="mt-2">
                    <label>Comentario</label>
                    <b-form-textarea v-model="form.answers[idx].detail"> </b-form-textarea>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <b-button id="btn-submit" class="is-primary" type="submit" variant="primary" :disabled="!isValidForm()">
            Enviar información
          </b-button>
          <br />
          <div class="text-center" v-if="submitting">
            <div class="spinner-border" role="status">
              <span class="sr-only">Enviando datos...</span>
            </div>
          </div>
        </div>
      </b-form>

      <b-modal ref="alert-modal" :width="640" scroll="keep" @ok="resetForm()" @cancel="resetForm()">
        <div class="container">
          <div class="content">
            <h3 class="is-5 spaced">
              {{ alert.title }}
            </h3>
            <p class="detail">
              {{ alert.detail }}
            </p>
          </div>
        </div>
      </b-modal>

      <b-modal ref="msg-modal" :width="640" scroll="keep" ok-only ok-variant="secondary" ok-title="Aceptar">
        <div class="container">
          <div class="content">
            <h3 class="is-5 spaced">
              {{ alert.title }}
            </h3>
            <p class="detail">
              {{ alert.detail }}
            </p>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      slider: {
        range: [
          {
            label: "Pobre",
          },
          {
            label: "Muy buena",
          },
        ],
      },
      survey: null,
      companies: [
        { value: 'knueveint', text: 'K-9 Internacional' },
        { value: 'cops', text: 'Cops K-9' },
        { value: 'abc', text: 'ABC' },
        { value: 'inspectors', text: 'Klean 9' },
      ],
      loadingEmployee: false,
      loadedEmployee: false,
      loading: true,
      submitting: false,
      priority: [],
      MAX_CHECKBOXES: 3,
      form: {
        surveyId: 11,
        name: "",
        company: "",
        userId: "",
        govId: "",
        code: "",
        departmentId: "",
        database: "knueveint",
        answers: [],
      },
      alert: {
        title: null,
        detail: null,
      },
    };
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_SERVER_ENDPOINT}/survey/byId`, {
        params: { id: this.form.surveyId },
      })
      .then((response) => {
        this.survey = response.data;
      })
      .catch((err) => {
        this.alert = {
          title: "Ha ocurrido un error",
          detail: err.response.data,
        };
        this.$refs["msg-modal"].hide();
      })
      .finally(() => {
        this.loading = false;
        if (this.survey) {
          this.prepareAnswersArray();
        }
      });
  },
  methods: {
    resetForm() {
      location.reload()
    },

    changeStatus(idx, data) {
      if (this.form.answers[idx].options.length < this.MAX_CHECKBOXES && !this.isChecked(idx, data)) {
        this.form.answers[idx].options.push(data)
      } else {
        const index = this.form.answers[idx].options.indexOf(data);
        if (index > -1) {
          this.form.answers[idx].options.splice(index, 1);
        }
      }
    },

    isChecked(idx, data) {
      for (let jdx = 0; jdx < this.form.answers[idx].options.length; jdx++) {
        if (this.form.answers[idx].options[jdx] == data) {
          return true;
        }
      }
      return false;
    },

    prepareAnswersArray() {
      for (let idx = 0; idx < this.survey.questions.length; idx++) {
        const question = this.survey.questions[idx];
        this.form.answers.push({
          questionId: question.id,
          options: [],
          detail: "",
        })
        if (+question.type == 6) {
          for (let jdx = 0; jdx < question.options.length; jdx++) {
            const option = question.options[jdx];
            this.priority.push(jdx + 1);
            this.form.answers[idx].options.push({
              optionId: option.id,
              priority: "",
            })
          }
        }
      }
    },

    isValidForm() {
      for (let idx = 0; idx < this.survey.questions.length; idx++) {
        const question = this.survey.questions[idx];
        const answer = this.form.answers[idx];
        if (question.required) {
          if (
            question.type == 1 ||
            question.type == 4 ||
            question.type == 7 ||
            question.type == 2 ||
            question.type == 3
          ) {
            if (answer.options.length == 0) {
              return false;
            }
          }
          if (question.type == 6) {
            for (let jdx = 0; jdx < answer.options.length; jdx++) {
              const option = answer.options[jdx];
              if (option.priority == "") {
                return false;
              }
            }
          }
          if (question.type == 5) {
            if (!answer.detail) {
              return false;
            }
          }
        }
      }
      return true;
    },

    isValidId() {
      return this.form.govId != "";
    },

    checkClient() {
      if (this.form.name == "" || this.form.company == "") {
        return false;
      } else {
        return true;
      }
    },

    doSearchEmployee() {
      this.loadingEmployee = true;
      axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT}/invoice/user`, {
        params: {
          govId: this.form.govId,
          database: this.form.database,
        }
      }).then(response => {
        if (response) {
          this.handleEmployee(response.data)
        } else {
          this.handleError()
        }
      }).catch(() => {
        this.handleError()
      }).finally(() => {
        this.loadingEmployee = false
      })
    },

    handleEmployee(employee) {
      this.form.userId = employee.id
      this.form.name = employee.name
      this.form.govId = employee.govId
      this.form.code = employee.code
      this.form.departmentId = employee.departmentId
      this.loadedEmployee = true;
    },

    capitalizeName(name) {
      let nameParts = name.split(" ");
      var cleanName = "";
      for (var i = 0; i < nameParts.length; i++) {
        let nm = nameParts[i].toLowerCase();
        nm = nm.charAt(0).toUpperCase() + nm.slice(1);
        if (i != 0) {
          cleanName += " ";
        }
        cleanName += nm;
      }
      return cleanName;
    },

    handleError() {
      this.alert = {
        title: "Empleado no encontrado",
        detail: "El empleado no ha sido encontrado en nuestra base de datos",
      };
      this.$refs["msg-modal"].show();
    },

    doSubmit() {
      const obj = { ...this.form };
      this.submitting = true;
      var data = JSON.stringify(obj);
      if (+this.survey.type === 1) {
        var surveyEndpoint = "save"
      }
      if (+this.survey.type === 2) {
        surveyEndpoint = "clients"
      }
      var config = {
          method: "post",
          url: `${process.env.VUE_APP_SERVER_ENDPOINT}/survey/${surveyEndpoint}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
      axios(config)
        .then(() => {
          alert(
            "Los datos han sido enviados exitosamente",
            "Gracias por su aporte"
          );
          location.reload();
        })
        .catch(err => {
          if (err.response) {
            this.alert = {
              title: "Atención",
              detail: err.response.data,
            };
            this.$refs["alert-modal"].show();
          }
        })
    },
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 20px;
  height: 100%;
}

#btn-submit {
  float: right;
}

.custom-button {
  background-color: light-grey;
  width: 100%;
}

.custom-button:active {
  width: 100%;
  background-color: rgba(2, 123, 255, 255) !important;
  border-color: rgba(2, 123, 255, 255) !important;
}

.btn-secondary.custom-button {
  border-color: rgba(2, 123, 255, 255) !important;
  background-color: white !important;
  color: black !important;
}

.btn-secondary.active.custom-button {
  color: white !important;
  background-color: rgba(2, 123, 255, 255) !important;
  border-color: rgba(2, 123, 255, 255) !important;
}
</style>
